import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { debounce } from 'underscore'

// Apply floating effect using sine wave
const floatHeight = 0.1;  // Adjust this value for desired float height
const floatSpeed = 1;  // Adjust this value for desired float speed
let targetRotation = { x: 0, y: 0 };  // Target rotation values
let targetPosition = { x: 0, y: 0 };
let lerpFactor = 0.05;  // Interpolation factor (adjust for desired speed)

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')
const scene = new THREE.Scene()

var model = null
const loader = new GLTFLoader();
loader.load('assets/element.glb', function (obj) {
    model = obj.scene;
    model.scale.set(1, 1, 1);
    model.traverse((child) => {
        if (child.isMesh) {
            child.material = new THREE.MeshPhysicalMaterial({
                color: 0xffffff, // Color of the material
                metalness: 1, // High value for metalness makes the material more reflective
                roughness: 0.5, // Low value for roughness makes the material shinier
                reflectivity: 1,
                clearcoat: 0.8, // Adding clearcoat for extra shine
                clearcoatRoughness: 0.2, // Adjusting clearcoat roughness
                side: THREE.DoubleSide,
                ior: 2,
                specularIntensity: 1,
                specularColor: 0xffffff
            });
        }
    });
    model.position.set(0, -5, 0)
    scene.add(model);

});



/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 0
camera.position.z = -3
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.enableZoom = false;
controls.enableRotate = false;
controls.enablePan = false;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
renderer.setClearColor(0x000000, 0);



// Add a SpotLight to the scene
const spotLight = new THREE.SpotLight(0xffffff, 6.5);
spotLight.position.set(-1.6, 5.7, -5.3);
spotLight.angle = Math.PI / 6; // Cone angle in radians
spotLight.penumbra = 0.1; // Penumbra - the amount of light falloff at the edge of the spotlight's cone
spotLight.decay = 2; // How the light dims along the distance of the beam
spotLight.distance = 200; // Maximum distance the light will reach
scene.add(spotLight);



// Mouse

function onMouseMove(event) {
    if (shouldFollowMouse) {
        // Normalize mouse position to range [-1, 1]
        const mouseX = (event.clientX / window.innerWidth) * 2 - 1;
        const mouseY = -(event.clientY / window.innerHeight) * 2 + 1;
        const rotationSpeed = 0.5;  // Adjust this value for different speeds
        targetRotation.x = mouseY * Math.PI * rotationSpeed * 0.1;
        targetRotation.y = - mouseX * Math.PI * rotationSpeed * 1.5;
    }

}

document.addEventListener('mousemove', onMouseMove, false);


/**
 * Animate
 */
const clock = new THREE.Clock()
var shouldRender = true
const tick = () => {
    const elapsedTime = clock.getElapsedTime();

    // Update controls
    // controls.update()
    // Update the model's rotation
    if (model) {
        // model.rotation.x += 0.01;
        // model.rotation.y += 0.01;

        model.rotation.x += (targetRotation.x - model.rotation.x) * lerpFactor;
        model.rotation.y += (targetRotation.y - model.rotation.y) * lerpFactor;

        if (shouldFlot) {
            targetPosition.y = (Math.sin(elapsedTime * floatSpeed) * floatHeight) - 0.5
        }

        model.position.y += (targetPosition.y - model.position.y) * lerpFactor;
    }


    // spotLightHelper.update();
    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    if(shouldRender){
        window.requestAnimationFrame(tick)
    }
}
tick()


var shouldFollowMouse = true;
var shouldFlot = true;



// todo: stop the three JS ticking when it is not visible anymore

const steps = [
    {
        up: function () {

        },
        down: function () {
            document.querySelector('.background').style.opacity = 0.8
        }
    },

    {
        up: function () {
            document.querySelector('.background').style.opacity = 0
        },
        down: function () {
            shouldFollowMouse = false;
            shouldFlot = false;

            targetPosition.y = 5;
            targetRotation.x = 0;
            targetRotation.y = 0;

            document.querySelector('.hero-text').classList.add('visible');
            document.querySelector('.hero-text h2').classList.add('animate');
            document.querySelector('.hero-text').classList.remove('reverse');
            setTimeout(function(){
                shouldRender = false;
                document.querySelector('.threejs').classList.remove('visible')
            }, 1000)
        }
    },
    {
        up: function () {
            shouldRender = true;
            tick()
            setTimeout(function () {
                targetPosition.y = (Math.sin(clock.getElapsedTime() * floatSpeed) * floatHeight) - 0.5;
            }, 500)

            setTimeout(function () {
                shouldFollowMouse = true;
                shouldFlot = true;
                document.querySelector('.hero-text').classList.remove('visible')
            }, 1700)

            document.querySelector('.hero-text h2').classList.remove('animate');
            document.querySelector('.hero-text').classList.add('reverse');
            document.querySelector('.threejs').classList.add('visible')

        },
        down: function () {
            document.querySelector('.hero-text').classList.remove('reverse');
            document.querySelector('.hero-text h1').classList.add('animate');
        }
    },
    {
        up: function () {
            document.querySelector('.hero-text h1').classList.remove('animate');
            document.querySelector('.hero-text').classList.add('reverse');
        },
        down: function () {
            document.querySelector('.hero-text').classList.remove('reverse');
            document.querySelector('.hero-text').classList.add('exit');
            document.querySelector('.history').classList.add('visible');
            document.querySelector('.history .h1').classList.add('visible');
            setTimeout(function () {
                document.querySelector('.history').classList.add('animate');
                document.querySelector('.history .map').classList.add('animate');
            }, 100)
        }
    }, {
        up: function () {
            document.querySelector('.hero-text').classList.add('visible');
            document.querySelector('.hero-text').classList.remove('reverse');
            document.querySelector('.hero-text h1').classList.add('animate');
            document.querySelector('.hero-text h2').classList.add('animate');


            document.querySelector('.hero-text').classList.remove('exit');
            document.querySelector('.history').classList.remove('animate');
            document.querySelector('.history .map').classList.remove('animate');
            document.querySelector('.history').classList.add('exit');
            setTimeout(function () {
                document.querySelector('.history').classList.remove('visible');
                document.querySelector('.history').classList.remove('exit');
                document.querySelector('.hero-text').classList.remove('exit');
            }, 1000);

        },
        down: function () {
            animateHistory(1,2)
        }
    }, {
        up: function () {
            animateHistory(2,1)
        },
        down: function () {
            animateHistory(2,3)
        }
    },{
        up: function () {
            animateHistory(3,2)
        },
        down: function () {
            animateHistory(3,4)
        }
    },
    {
        up: function(){
            animateHistory(4,3)
        },
        down: function(){
            document.body.classList.add('black');
            document.querySelector('.history .h4').classList.remove('visible');
            document.querySelector('.history').classList.remove('animate');
            document.querySelector('.history .map').classList.remove('animate');
            setTimeout(function () {
                document.querySelector('.history').classList.remove('visible');
                document.querySelector('.holdings').classList.add('animate');
            }, 1400);

    
            setTimeout(function(){
                document.querySelector('.holdings').classList.add('visible');
                document.querySelector('.background').style.opacity = 0
                document.querySelector('.history').classList.add('fadeOut');
            }, 500)
        }
    },
    {
        up: function(){

            document.querySelector('.holdings').classList.remove('animate');
            document.querySelector('.history').classList.remove('fadeOut');
            setTimeout(function(){
                document.querySelector('.background').style.opacity = 1
                document.querySelector('.holdings').classList.remove('visible');
                document.querySelector('.history').classList.add('visible');
                document.querySelector('.history .h4').classList.add('visible');
                

            }, 500);

            setTimeout(function(){
                document.querySelector('.history').classList.add('animate');
                document.querySelector('.history .map').classList.add('animate');
            },600)

        },
        down: function(){

        }
    }
]


var isScrolling = false
var step = 0
var maxSteps = steps.length
function down() {
    if(isScrolling){ return }
    if (step < maxSteps - 1) {
        steps[step].down()
        step++;
    }
    isScrolling = true;
    setTimeout(function(){
        isScrolling = false
    }, 1000);

}

function up() {
    if(isScrolling){ return }
    if (step > 0) {
        steps[step].up()
        step--;
    }
    isScrolling = true;
    setTimeout(function(){
        isScrolling = false
    }, 1000);

}

// Handle wheel event for mouse scroll
function handleWheel(event) {
    if (event.deltaY > 0) {
        down();
    } else {
        up();
    }
    cursor.classList.add("scroll")
}

document.addEventListener('touchstart', handleTouchStart, false);        
document.addEventListener('touchmove', handleTouchMove, false);

var yDown = null;

function getTouches(evt) {
  return evt.touches ||             // browser API
         evt.originalEvent.touches; // jQuery
}                                                     
                                                                         
function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];                                                                     
    yDown = firstTouch.clientY;                                      
};                                                
                                                                         
function handleTouchMove(evt) {
    if ( ! yDown ) {
        return;
    }
    var yUp = evt.touches[0].clientY;
    var yDiff = yDown - yUp;
                                                                        
    if ( yDiff > 0 ) {
        down()
    } else { 
        up()
    }                                                                 
    
    yDown = null;                                             
};

// Add event listeners
window.addEventListener('wheel', debounce(handleWheel, 50, true));


window.addEventListener("click", debounce(function () {
    cursor.classList.add("click");
}, 50, true));


// cursore code
const cursor = document.getElementById("cursor");
function handleMouseMove(event) {
    event = event || window.event;
    cursor.style.top = (event.clientY - 25) + "px";
    cursor.style.left = (event.clientX - 25) + "px";
}
cursor.addEventListener("animationend", function () {
    cursor.classList.remove("scroll");
    cursor.classList.remove("click");
});

document.addEventListener('mousemove', handleMouseMove, false);

const menu = document.getElementById("menu")
menu.addEventListener("click", toggleSidebar)
var sidebarOpen = false

function toggleSidebar(){
    sidebarOpen = !sidebarOpen
    menu.classList.toggle("open")
    document.querySelector(".root").classList.toggle("blur")
    document.querySelector(".sidebar").classList.toggle("open")
    if(sidebarOpen){
        shouldFollowMouse = false
        isScrolling = true
    }else {
        shouldFollowMouse = true
        isScrolling = false
    }
    // todo: stop rendring when the side bar is open
    // also stop scrolling
}

setTimeout(function () {
    menu.classList.remove("animate")
}, 2300)

const isMobile = /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

if (isMobile) {
    cursor.style.display = "none"; // hide custom cursor
    shouldFollowMouse = false;

    const interactiveArea = document.querySelector('canvas');
    const isPermissionNeeded = typeof DeviceMotionEvent.requestPermission === 'function'

    if (isPermissionNeeded) {
        interactiveArea.addEventListener('click', requestMotionPermission, { once: true });
        interactiveArea.addEventListener('touchstart', requestMotionPermission, { once: true });
    } else {
        window.addEventListener("deviceorientation", handleOrientation);
    }
}

function handleOrientation(event) {
    let alpha = event.alpha ? THREE.MathUtils.degToRad(event.alpha) : 0; // Z-axis
    let beta = event.beta ? THREE.MathUtils.degToRad(event.beta) : 0;   // X-axis
    let gamma = event.gamma ? THREE.MathUtils.degToRad(event.gamma) : 0; // Y-axis
    console.log(alpha, beta, gamma);

    const rotationSpeed = 0.5;  // Adjust this value for different speeds
    targetRotation.x = beta * Math.PI * rotationSpeed * 0.1;
    targetRotation.y = - gamma * Math.PI * rotationSpeed * 0.4;
}

function requestMotionPermission() {
    DeviceMotionEvent.requestPermission()
        .then(response => {
            if (response === 'granted') {
                window.addEventListener("deviceorientation", handleOrientation);
            } else {
                console.log('Permission denied');
            }
        })
        .catch(console.error);

}

function animateHistory(x,y){
    document.querySelector('.history').classList.remove('animate');
    setTimeout(function () {
        document.querySelector('.history .h' + x).classList.remove('visible');
        document.querySelector('.history .h' + y).classList.add('visible');
    }, 1400);

    setTimeout(function () {
        document.querySelector('.history').classList.add('animate');
    }, 1500);
}


document.querySelectorAll('.sidebar a').forEach(function(el){
    el.addEventListener('click', function(e){
        var target = "";
        if(e.target.hash == "#home"){
            resetHomePage();
            tick();
            target = "#home"
        }else if(e.target.hash == "#our-journey"){
            gotoJourney()
            target = "#home";

        }else{
            isScrolling = true;
            shouldRender = false;
            target = e.target.hash;
        }

        document.querySelector('.page.visible').classList.remove("visible");
        document.querySelector(target).classList.add("visible");
        toggleSidebar();
        
    });
});


function gotoJourney(){
    document.querySelector('.background').style.opacity = 0.8;
    document.querySelectorAll("#home .content.visible, .history .h1, .history .h2, .history .h3, .history .h4, .hero-text h1, .hero-text h2").forEach((el) => el.classList.remove('visible', 'animate'))
    document.querySelector("#home .content.history").classList.add("visible")

    model.position.y = 5;
    model.rotation.x = 0;
    model.rotation.y = 0;
    
    step = 4;
    
    shouldFollowMouse = false;
    shouldFlot = false;
    shouldRender = false;
    isScrolling = false;
    
    document.querySelector('.history').classList.add('visible');
    document.querySelector('.history .h1').classList.add('visible');
    setTimeout(function () {
        document.querySelector('.history').classList.add('animate');
        document.querySelector('.history .map').classList.add('animate');
    }, 100)
}


function resetHomePage(){
    step = 0
    document.querySelector('.background').style.opacity = 0
    isScrolling = false
    shouldRender = true
    shouldFollowMouse = true;
    shouldFlot = true;
    model.position.set(0, -5, 0);
    targetRotation = { x: 0, y: 0 };
    targetPosition = { x: 0, y: 0 };

    document.querySelectorAll("#home .content.visible, .history .h1, .history .h2, .history .h3, .history .h4, .hero-text ,.hero-text h1, .hero-text h2").forEach((el) => el.classList.remove('visible', 'animate'))
    document.querySelector("#home .content.threejs").classList.add("visible");
    document.querySelector(".hero-text.exit")?.classList.remove("exit")

}

if (!isMobile){
    document.querySelectorAll(".history p").forEach((el) =>{
        el.addEventListener("mouseenter", (event) => {
            event.target.parentNode.classList.add("hover")
        })
        el.addEventListener("mouseleave", (event) => {
            event.target.parentNode.classList.add("hoverOut")
            event.target.parentNode.classList.remove("hover")
            event.target.parentNode.classList.add("hoverOut")
            setTimeout(() => {
                event.target.parentNode.classList.remove("hoverOut")
            }, 500);
        })
    })
}
